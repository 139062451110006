/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { UsersPartyPermissionsReadType } from '@portal/api/enums/users-party-permissions-read-type';
import { UsersOrganizationsPermissionsReadType } from '@portal/api/enums/users-organizations-permissions-read-type';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'orgs',
        title: 'Organizações',
        subtitle: 'Gestão de organizações',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'orgs.users',
                title: 'Utilizadores',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/organizations/users/active',
                needOrg: true,
                permissions: [UsersOrganizationsPermissionsReadType.IsOwner]
            },
        ]
    },
    {
        id: 'parties',
        title: 'Eventos',
        subtitle: 'Eventos da organização',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'parties',
                title: 'Eventos',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: '/parties'
            },
            {
                id: 'dashboard',
                title: 'Painel',
                type: 'basic',
                icon: 'mat_outline:dashboard',
                link: 'party/dashboard',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.DashboardRead]
            },
            {
                id: 'revenues',
                title: 'Receitas',
                type: 'basic',
                icon: 'heroicons_outline:cash',
                link: '/party/revenues',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.RevenuesRead]
            },
            {
                id: 'expenses',
                title: 'Despesas',
                type: 'basic',
                icon: 'mat_solid:work_outline',
                link: '/party/expenses',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.ExpensesRead]
            },
            {
                id: 'events',
                title: 'Actividades',
                type: 'basic',
                icon: 'mat_outline:event',
                link: 'party/events',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.EventsRead]
            },
            {
                id: 'games',
                title: 'Jogos',
                type: 'basic',
                icon: 'mat_outline:games',
                link: '/party/games',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.GamesRead]
            },
            {
                id: 'donations',
                title: 'Donativos',
                type: 'basic',
                icon: 'mat_outline:euro',
                link: '/party/donations',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.DonationRead]
            },
            {
                id: 'sponsors',
                title: 'Patrocinadores',
                type: 'basic',
                icon: 'feather:award',
                link: '/party/sponsors',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.SponsorsRead]
            },
            {
                id: 'partyNotes',
                title: 'Notas',
                type: 'basic',
                icon: 'heroicons_outline:annotation',
                link: '/party/notes',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.PartyNotesRead]
            },
            {
                id: 'roadmap',
                title: 'Guião / Tarefas',
                type: 'basic',
                icon: 'feather:file-text',
                link: '/party/roadmap',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.RoadmapRead]
            },
            {
                id: 'raffles_remove',
                title: 'Rifas',
                type: 'basic',
                icon: 'heroicons_outline:ticket',
                link: '/party/raffles',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.RafflesRead]
            },
            {
                id: 'users',
                title: 'Utilizadores',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/party/users/active',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.IsOwner]
            },
            {
                id: 'collaborators',
                title: 'Colaboradores',
                type: 'basic',
                icon: 'feather:briefcase',
                link: '/party/collaborators',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.IsOwner]
            },
            {
                id: 'members',
                title: 'Sócios',
                type: 'basic',
                icon: 'feather:users',
                link: '/party/members',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.MembersRead]
            },
            {
                id: 'stands',
                title: 'Expositores',
                type: 'basic',
                icon: 'mat_solid:storefront',
                link: '/party/stands',
                needParty: true,
                permissions: [UsersPartyPermissionsReadType.StandsRead]
            },
            {
                id: 'support',
                title: 'Suporte',
                type: 'basic',
                icon: 'mat_outline:support_agent',
                link: '/support',
            },
        ]
    },
    {
        id: 'admin',
        title: 'ADMIN',
        subtitle: 'Administração',
        type: 'group',
        icon: 'heroicons_outline:home',
        roles: ["Admin", "Manager"],
        children: [
            {
                id   : 'admin.orgs',
                title: 'Organizações',
                type : 'basic',
                icon : 'mat_solid:house',
                link : '/admin/organizations',
                roles: ["Manager","Admin"]
            },
            {
                id   : 'admin.users.plans',
                title: 'Planos utilizadores (Eventos)',
                type : 'basic',
                icon : 'heroicons_outline:book-open',
                link : '/admin/user-plans',
                roles: ["Manager","Admin"]
            },
            {
                id   : 'admin.users.sales',
                title: 'Vendas',
                type : 'basic',
                icon : 'heroicons_outline:briefcase',
                link : '/admin/sales',
                roles: ["Manager","Admin"]
            }
        ]
    },

    // {
    //     id   : 'example',
    //     title: 'Example',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // }

];

/**
 * only is being used defaultNavigation
 * ignore others
 */
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
