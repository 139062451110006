import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { NotFoundComponent } from '@portal/shared/not-found/not-found.component';
import {InitialDataResolver} from "./app.resolvers";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'parties' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'parties' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)
            },
            {
                path: 'confirm-email',
                loadChildren: () => import('app/modules/auth/confirm-email/confirm-email.module').then(m => m.AuthConfirmEmailModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)
            },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            {
                path: 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)
            }
        ]
    },

    // Landing routes
    {

        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/landing/landing.module').then(m => m.LandingModule) },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'parties',
                loadChildren: () => import('app/modules/admin/user-parties/user-parties.module').then(m => m.UserPartiesModule)
            },
            {
                path: 'party',
                loadChildren: () => import('app/modules/admin/party/party.module').then(m => m.PartyModule)
            },
            {
                path: 'organizations',
                loadChildren: () => import('app/modules/admin/organizations/organizations.module').then(m => m.OrganizationsModule)
            },
            {
                path: 'payments',
                loadChildren: () => import('app/modules/admin/payments/payments.module').then(m => m.PaymentsModule),
                resolve: {
                    initialData: InitialDataResolver
                },
            },
            {
                path: 'support', loadChildren: () => import('app/modules/admin/support/support.module').then(m => m.SupportModule),
                resolve: {
                    initialData: InitialDataResolver
                },
            },
            {
                path: 'admin',
                loadChildren: () => import('app/modules/management/management.module').then(m => m.ManagementModule)
            },
        ]
    },
    { path: '**', component: NotFoundComponent },
];
