import {
    Component,
    ViewEncapsulation
} from '@angular/core';
import {PartyEventProductResponse} from '@portal/api/responses/PartyEventProduct/party-event-product-response';
import {PartyEventProductsService} from '@portal/api/party-event-products.service';
import {SearchRequest} from '@portal/api/requests/search-request';
import {Guid} from 'guid-typescript';
import {EventProductsCalculatorService} from "./event-products-calculator.service";

@Component({
    selector: 'app-event-products-calculator',
    templateUrl: './event-products-calculator.component.html',
    styleUrls: ['./event-products-calculator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventProductsCalculatorComponent  {
    eventId: Guid;
    isCalculatorVisible: boolean;
    products: PartyEventProductResponse[];
    price: number;
    sortDirection = 1;
    isPartialDeletingCalculator: boolean;

    constructor(
        private eventProductsService: PartyEventProductsService,
        private eventProductsCalculatorService: EventProductsCalculatorService,
    ) {
    }

    ngOnInit(): void {
        this.eventProductsCalculatorService.connect().subscribe(res => {
            this.isCalculatorVisible = res.open;
            if (this.isCalculatorVisible) {
                this.hideLayout();

                this.eventId = res.eventId;
                if (this.eventId) {
                    this.eventProductsService.listByEvent(
                        {page: 0, pageSize: 600, sortDirection: this.sortDirection} as SearchRequest,
                        this.eventId).subscribe(resp => {
                        this.products = resp?.data?.items;
                        this.resetCalculatingScore();
                    });
                }
            } else {
                this.resetLayout();
                this.products = [];
            }

        })

    }

    private hideLayout() {
        const elem = document.getElementById('layout-elem');
        if (elem) {
            elem.style.display = 'none';
        }
    }
    private resetLayout() {
        const elem = document.getElementById('layout-elem');
        if (elem) {
            elem.style.display = 'block';
        }
    }

    calculateScore() {
        this.price = this.products.reduce((accumulator, obj) => {
            return accumulator + (obj.touchedTimes * obj.price);
        }, 0);

    }

    priceClicked(id: Guid) {
        const pointClickedIndex = this.products.findIndex(x => x.id === id);
        if (!this.isPartialDeletingCalculator) {
            this.products[pointClickedIndex].touchedTimes += 1;
        } else {
            if (this.products[pointClickedIndex].touchedTimes > 0) {
                this.products[pointClickedIndex].touchedTimes -= 1;
            }
        }
        this.calculateScore();
    }

    resetCalculatingScore() {
        this.products.map(x => x.touchedTimes = 0);
        this.price = 0;
    }

    close() {
        this.eventProductsCalculatorService.modalSet({open: false})
    }

    partialDeleteCalculator() {
        this.isPartialDeletingCalculator = !this.isPartialDeletingCalculator;
    }
}
