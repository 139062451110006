import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiBaseService {
    controller: string;
    private headers = { 'Content-Type': 'application/json' };
    private readonly baseUrl: string;

    constructor(public http: HttpClient) {
        this.baseUrl = environment.apiUrl;
    }

    putMultiPart<T, R>(obj: R, url = ''): Observable<T> {
        return this.http.put<T>(this.getUrl(url), obj, { headers: new HttpHeaders() });
    }

    postMultipart<T, R>(obj: R, url = ''): Observable<T> {
        return this.http.post<T>(this.getUrl(url), obj, { headers: new HttpHeaders() , withCredentials: true });
    }

    post<T, R>(obj: R, url = ''): Observable<T> {
        return this.http.post<T>(this.getUrl(url), JSON.stringify(obj), { headers: this.headers, withCredentials: true });
    }

    protected put<T, R>(obj: R, url = ''): Observable<T> {
        return this.http.put<T>(this.getUrl(url), JSON.stringify(obj), { headers: this.headers });
    }

    protected deleteBase<T>(url = ''): Observable<T> {
        return this.http.delete<T>(this.getUrl(url), { headers: this.headers });
    }

    protected get<T>(url = ''): Observable<T> {
        return this.http.get<T>(this.getUrl(url), { headers: this.headers });
    }

    protected getFile(url: string): Observable<HttpResponse<Blob>> {
        return this.http.get(this.getUrl(url), {
            observe: 'response',
            responseType: 'blob'
        });
    }
    protected postFile(url: string, body: any): Observable<HttpResponse<Blob>> {
        return this.http.post<Blob>(this.getUrl(url), body, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    private getUrl(url: string) {
        return `${this.baseUrl}${url}`;
    }
}
