<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions"
    [matBadge]="TotalInvites" matBadgeColor="accent" [matBadgeHidden]="TotalInvites <= 0">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user.avatar"
            [src]="user.avatar">
        <mat-icon
            *ngIf="!showAvatar || !user.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'}"
        ></span>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Conectado como</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
<!--    <button mat-menu-item>-->
<!--        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>-->
<!--        <span>Perfil</span>-->
<!--    </button>-->
    <button mat-menu-item [routerLink]="['/party/invites']"
            [matMenuTriggerFor]="invites">
        <mat-icon [svgIcon]="'heroicons_outline:mail'"></mat-icon>
        <span>Convites</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sair</span>
    </button>
</mat-menu>
<mat-menu
    class="user-status-menu"
    #invites="matMenu">
    <button
        mat-menu-item
        [routerLink]="['/party/invites']">
        <span>Eventos</span>
        <span class="absolute right-4" [matBadge]="partyPendingInvites" matBadgeColor="accent" [matBadgeHidden]="partyPendingInvites <= 0"></span>
    </button>
    <button
        mat-menu-item
        [routerLink]="['/organizations/invites']">
        <span>Organizações</span>
        <span class="absolute right-4" [matBadge]="orgPendingInvites" matBadgeColor="accent" [matBadgeHidden]="orgPendingInvites <= 0"></span>
    </button>

</mat-menu>
