import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiBaseService } from './api-base-service';
import { Observable } from 'rxjs';
import { ApiResponse, ListResponse } from '@portal/api/responses/api-response';
import { SearchRequest } from '@portal/api/requests/search-request';
import { Guid } from 'guid-typescript';
import { PartyInvitesResponse } from '@portal/api/responses/party-invites/party-invites-response';
import { PartyInviteAddRequest } from '@portal/api/requests/party-invites/party-invite-add-request';
import { PartyInviteUserResponse } from '@portal/api/responses/party-invites/party-invite-user-response';

@Injectable({
    providedIn: 'root'
})
export class PartyInviteService extends ApiBaseService {
    controller = 'partiesinvites';

    constructor(public http: HttpClient) {
        super(http);
    }


    add(obj: PartyInviteAddRequest): Observable<ApiResponse<string>> {
        return this.post<ApiResponse<string>, PartyInviteAddRequest>(obj, this.controller + '/add');
    }

    getByParty(obj: SearchRequest): Observable<ApiResponse<ListResponse<PartyInvitesResponse[]>>> {
        return this.post<ApiResponse<ListResponse<PartyInvitesResponse[]>>, SearchRequest>(obj, `${this.controller}/GetByParty`);
    }

    getByUser(obj: SearchRequest): Observable<ApiResponse<ListResponse<PartyInviteUserResponse[]>>> {
        return this.post<ApiResponse<ListResponse<PartyInviteUserResponse[]>>, SearchRequest>(obj, `${this.controller}/GetByUser`);
    }

    deleteInvite(userId: Guid): Observable<ApiResponse<boolean>> {
        return this.deleteBase<ApiResponse<boolean>>(`${this.controller}/${userId}`);
    }

    accept(inviteId: Guid): Observable<ApiResponse<boolean>> {
        return this.get<ApiResponse<boolean>>(`${this.controller}/accept?inviteId=${inviteId}`);
    }

    reject(inviteId: Guid): Observable<ApiResponse<boolean>> {
        return this.get<ApiResponse<boolean>>(`${this.controller}/reject?inviteId=${inviteId}`);
    }

    getPendingByUser(): Observable<ApiResponse<number>> {
        return this.post<ApiResponse<number>, SearchRequest>(null, `${this.controller}/GetPendingByUser`);
    }
}
