import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base-service';
import { HttpClient } from '@angular/common/http';
import { SearchRequest } from './requests/search-request';
import { Observable } from 'rxjs';
import { ApiResponse, ListResponse } from './responses/api-response';
import { PartyEventProductResponse } from './responses/PartyEventProduct/party-event-product-response';
import { PartyEventsProductUpdateRequest } from '@portal/api/requests/party-events-products/party-events-product-update-request';
import { Guid } from 'guid-typescript';
import { PartyEventsProductAddRequest } from '@portal/api/requests/party-events-products/party-events-product-add-request';

@Injectable({
    providedIn: 'root'
})

export class PartyEventProductsService extends ApiBaseService {
    controller: string;

    constructor(public http: HttpClient) {
        super(http);
        this.controller = 'partyeventproducts';
    }

    add(obj: PartyEventsProductAddRequest): Observable<ApiResponse<string>> {
        return this.post<ApiResponse<string>, PartyEventsProductAddRequest>(obj, this.controller + '/add');
    }

    update(obj: PartyEventsProductUpdateRequest): Observable<ApiResponse<string>> {
        return this.put<ApiResponse<string>, PartyEventsProductUpdateRequest>(obj, this.controller + '/update');
    }

    getFormEdit(productId: string): Observable<ApiResponse<PartyEventsProductUpdateRequest>> {
        return this.get<ApiResponse<PartyEventsProductUpdateRequest>>(this.controller + `/form/edit/${productId}`);
    }

    listByEvent(obj: SearchRequest, eventId: Guid): Observable<ApiResponse<ListResponse<PartyEventProductResponse[]>>> {
        return this.post<ApiResponse<ListResponse<PartyEventProductResponse[]>>, SearchRequest>(obj, this.controller + `/ListAllByEvent?eventId=${eventId}`);
    }

    deleteProduct(id: Guid): Observable<ApiResponse<boolean>> {
        return this.deleteBase<ApiResponse<boolean>>(`${this.controller}/${id}`);
    }
}
