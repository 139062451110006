<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="(navigation$ | async)?.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img
                    class="w-8"
                    src="assets/images/logo/logo.svg">
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!--                <notifications></notifications>-->
                <user [showAvatar]="false"></user>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <ng-container   *ngIf="user$ | async as user">
                <div class="relative w-24 h-24">
                    <img
                        class="w-full h-full rounded-full"
                        *ngIf="user.avatar"
                        [src]="user.avatar"
                        alt="User avatar">
                    <mat-icon
                        class="icon-size-24"
                        *ngIf="!user.avatar"
                        [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </div>
                <div class="flex flex-col items-center justify-center w-full mt-6">
                    <div
                        class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                        {{user.name}}
                    </div>
                    <div
                        class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                        {{user.email}}
                    </div>
                </div>
            </ng-container>



            <div class="flex justify-center w-full mt-6 underline cursor-pointer"
                 *ngIf="org$ | async as org"
                 >
                <a class="text-center" [routerLink]="['/organizations/details']" >{{org.name}}</a>
                <ng-container *ngIf="orgList$ | async as orglist">
                    <mat-icon *ngIf="orglist.length > 1" svgIcon="mat_solid:keyboard_arrow_down" [matMenuTriggerFor]="orgListTpl"></mat-icon>
                </ng-container>
            </div>
            <mat-menu
                [xPosition]="'before'"
                #orgListTpl="matMenu">
                <span class="org-dropdown-title px-2">
                    <span>Organizações</span>
                </span>
                <mat-divider ></mat-divider>
                <button *ngFor="let item of (orgList$ | async)" mat-menu-item (click)="selectThisOrg(item)">
                    <span>{{item.name}}</span>
                </button>

            </mat-menu>
            <div class="flex items-center justify-center w-full mt-6 underline cursor-pointer"
                 *ngIf="(party$ | async) as party"
                 [routerLink]="['/party/details']">{{party.name}}
                <mat-icon svgIcon="mat_solid:call_missed_outgoing"></mat-icon>
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div id="mainDiv" class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div id="header"
        class="fixed flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            class="hamburger-menu"
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
        </div>
    </div>

    <!-- Content -->
    <div id="body-content" class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="mt-6 relative w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden grid grid-cols-1 sm:grid-cols-3">
        <div class="flex flex-wrap sm:flex-nowrap sm:col-span-3 items-center">
            <span class="font-medium text-secondary mr-4">Organiza360 &copy; {{currentYear}}</span>
            <a
                class="mr-4 sm:ml-auto hover:underline"
                [routerLink]="['/terms-conditions']" [queryParams]="{p: 'inner'}">Termos e Condições
            </a>
            <a
                class="mr-4 hover:underline"
                [routerLink]="['/privacy']" [queryParams]="{p: 'inner'}">Politicas e privacidade
            </a>
            <a
                class="hover:underline"
                [routerLink]="['/cookies']" [queryParams]="{p: 'inner'}">Cookies
            </a>
        </div>
    </div>


</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
