import { Injectable } from '@angular/core';
import { UsersPartyPermissionsWriteType } from '@portal/api/enums/users-party-permissions-write-type';
import { UsersPartyPermissionsReadType } from '@portal/api/enums/users-party-permissions-read-type';
import { ApiResponse } from '@portal/api/responses/api-response';
import { PermissionsByUserResponse } from '@portal/api/responses/permissions/permissions-by-user-response';
import {BehaviorSubject, filter, map, mapTo, Observable, shareReplay, tap, throttleTime} from 'rxjs';
import { PartyPermissionsService } from '@portal/api/party-permissions.service';
import { AuthService } from '../../core/auth/auth.service';
import { OrganizationSelectorService } from '@portal/shared/services/organization-selector.service';
import { UsersOrganizationsPermissionsReadType } from '@portal/api/enums/users-organizations-permissions-read-type';
import {PartySelectedService} from "@portal/shared/services/party-selected.service";
import {UsersOrganizationsPermissionsWriteType} from "@portal/api/enums/users-organizations-permissions-write-type";

@Injectable({
    providedIn: 'root',
})

export class UserPermissionsService {
    private permisssions$ = new BehaviorSubject<PermissionsByUserResponse>(null);
    private permisssionsPartyWrite$ = new BehaviorSubject<UsersPartyPermissionsWriteType[]>(null);
    private permisssionsOrganizationsPartyWrite$ = new BehaviorSubject<UsersOrganizationsPermissionsWriteType[]>(null);

    get currentPermissions(): PermissionsByUserResponse {
        return this.permisssions$.getValue();
    }

    get currentPermissions$(): Observable<PermissionsByUserResponse> {
        return this.permisssions$.asObservable().pipe(
            filter(item => item != null),
            shareReplay(),
            tap(res => {
                this.permisssionsOrganizationsPartyWrite$.next(this.createOrganizationsWritePermissions(res));
                this.permisssionsPartyWrite$.next(this.createPartyWritePermissions(res));
            }));
    }


    /**
     *  This will emit true for a specific permission provided
     * @param permission
     */
    currentWritePartyPermissions$(permission: UsersPartyPermissionsWriteType): Observable<boolean> {
        return this.permisssionsPartyWrite$.asObservable().pipe(filter(res => {
                if (!res) {
                    return false;
                }
                return res.some(p => (
                    p === permission || p === UsersPartyPermissionsWriteType.IsOwner))
            }),
            mapTo(true))
    }

    currentWriteOrganizationsPermissions$(permission: UsersOrganizationsPermissionsWriteType): Observable<boolean> {
        return this.permisssionsOrganizationsPartyWrite$.asObservable().pipe(filter(res => {
                if (!res) {
                    return false;
                }
                return res.some(p => (
                    p === permission || p === UsersOrganizationsPermissionsWriteType.IsOwner))
            }),
            mapTo(true))
    }

    hasOrganizationWritePermissionValue(permission: UsersOrganizationsPermissionsWriteType) {
        return this.permisssionsOrganizationsPartyWrite$.getValue()?.some(p => (
            p === permission || p === UsersOrganizationsPermissionsWriteType.IsOwner));
    }

    hasPartyWritePermissionValue(permission: UsersPartyPermissionsWriteType) {
        return this.permisssionsPartyWrite$.getValue()?.some(p => (
            p === permission || p === UsersPartyPermissionsWriteType.IsOwner));
    }

    hasWritePermission(permission: UsersPartyPermissionsWriteType) {
        return this.getWritePermissionsList()
            .some(p => (
                p === permission || p === UsersPartyPermissionsWriteType.IsOwner));
    }

    constructor(private _permissionsService: PartyPermissionsService,
                private _organizationSelectorService: OrganizationSelectorService,
                private _partySelectedService: PartySelectedService,
                private _authService: AuthService) {

        //current selected party changed, will load new permissions
        this._partySelectedService.currentParty.pipe(throttleTime(100))
            .subscribe(res => {
                this.load();
            })
    }

    private load() {
        if (this._authService.isAuthenticated && this._organizationSelectorService.hasOrgSelected()) {
            this.loadPermissions().subscribe();
        }
    }

    getWritePermissionsList(): UsersPartyPermissionsWriteType[] {
        const permissions = this.currentPermissions;
        return this.createPartyWritePermissions(permissions);
    }

    private createPartyWritePermissions(permissions: PermissionsByUserResponse): UsersPartyPermissionsWriteType[] {
        if (permissions) {
            const permissionsList = [];
            for (const permissionValue of Object.values(UsersPartyPermissionsWriteType)) {
                if (permissions.partyWrite & UsersPartyPermissionsWriteType[permissionValue]) {
                    permissionsList.push(UsersPartyPermissionsWriteType[permissionValue]);
                }
            }
            return permissionsList;
        }
        return [];
    }


    private createOrganizationsWritePermissions(permissions: PermissionsByUserResponse): UsersOrganizationsPermissionsWriteType[] {
        if (permissions) {
            const permissionsList = [];
            for (const permissionValue of Object.values(UsersOrganizationsPermissionsWriteType)) {
                if (permissions.orgWrite & UsersOrganizationsPermissionsWriteType[permissionValue]) {
                    permissionsList.push(UsersOrganizationsPermissionsWriteType[permissionValue]);
                }
            }
            return permissionsList;
        }
        return [];
    }

    getPartyReadPermissionsList(): UsersPartyPermissionsReadType[] {
        const permissions = this.currentPermissions;
        if (permissions) {
            const permissionsList = [];
            for (const permissionValue of Object.values(UsersPartyPermissionsWriteType)) {
                if (permissions.partyRead & UsersPartyPermissionsWriteType[permissionValue]) {
                    permissionsList.push(UsersPartyPermissionsWriteType[permissionValue]);
                }
            }
            return permissionsList;
        }
        return [];
    }

    loadPermissions(): Observable<ApiResponse<PermissionsByUserResponse>> {
        return this._permissionsService.getOwnPermissions().pipe(tap(permi => {
            this.permisssions$.next(permi.data);
        }));
    }

    hasReadPermission(permission: UsersPartyPermissionsReadType) {
        return this.getPartyReadPermissionsList().some(p => (
            p === permission || p === UsersPartyPermissionsReadType.IsOwner));
    }

    get isOrgOwner$() {
        return this.permisssions$.asObservable().pipe( map( permissions => {
            if(!permissions){
                return false;
            }
            return (this.currentPermissions.orgWrite & UsersPartyPermissionsWriteType.IsOwner) > 0;
        }))
    }


    getPartyPermissionsTable(): any[] {
        return [
            {
                name: 'Painel',
                read: UsersPartyPermissionsReadType.DashboardRead,
                write: UsersPartyPermissionsWriteType.DashboardWrite
            },
            {
                name: 'Donativos',
                read: UsersPartyPermissionsReadType.DonationRead,
                write: UsersPartyPermissionsWriteType.DonationWrite
            },
            {
                name: 'Jogos', read: UsersPartyPermissionsReadType.GamesRead,
                write: UsersPartyPermissionsWriteType.GamesWrite
            },
            {
                name: 'Patrocinadores',
                read: UsersPartyPermissionsReadType.SponsorsRead,
                write: UsersPartyPermissionsWriteType.SponsorsWrite
            },
            {
                name: 'Actividades',
                read: UsersPartyPermissionsReadType.EventsRead,
                write: UsersPartyPermissionsWriteType.EventsWrite
            },
            {
                name: 'Receitas',
                read: UsersPartyPermissionsReadType.RevenuesRead,
                write: UsersPartyPermissionsWriteType.RevenuesWrite
            },
            {
                name: 'Despesas',
                read: UsersPartyPermissionsReadType.ExpensesRead,
                write: UsersPartyPermissionsWriteType.ExpensesWrite
            },
            {
                name: 'Notas',
                read: UsersPartyPermissionsReadType.PartyNotesRead,
                write: UsersPartyPermissionsWriteType.PartyNotesWrite
            },
            {
                name: 'Guião',
                read: UsersPartyPermissionsReadType.RoadmapRead,
                write: UsersPartyPermissionsWriteType.RoadmapWrite
            },
            {
                name: 'Rifas',
                read: UsersPartyPermissionsReadType.RafflesRead,
                write: UsersPartyPermissionsWriteType.RafflesWrite
            },
            {
                name: 'Sócios',
                read: UsersPartyPermissionsReadType.MembersRead,
                write: UsersPartyPermissionsWriteType.MembersWrite
            },
            {
                name: 'Expositores',
                read: UsersPartyPermissionsReadType.StandsRead,
                write: UsersPartyPermissionsWriteType.StandsWrite
            },
        ];
    }

    getPermissionsOrganizations() {
        return [
            {
                name: 'Aceder a todos os eventos',
                read: UsersOrganizationsPermissionsReadType.ClosedParties,
                write: 0
            }
        ];
    }
}
