<fuse-alert
    *ngIf="alert"
    [appearance]="'outline'"
    [showIcon]="false"
    [type]="alert.type">
    {{alert.message}}
    <button (click)="closeAlert()" fuse-alert-close>
        <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
    </button>
</fuse-alert>
