import {Injectable} from '@angular/core';
import {AuthToken} from '../../api/responses/Auth/auth-token';
import {PartyResponse} from '../../api/responses/Parties/party-response';
import {Guid} from 'guid-typescript';
import {UserInfoResponse} from '@portal/api/responses/User/user-info-response';
import {Org} from "@portal/api/responses/organizations/org-details-response";
import { jwtDecode } from 'jwt-decode';
import {ExpenseResponse} from "@portal/api/responses/Expenses/expense-response";

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private authObjKey = 'authState';
    private partyKey = 'party';
    private orgKey = 'org';
    private userKey = 'currentUser';
    private lastUserKey = 'lastUser';

    getObject(key: string): any {
        return JSON.parse(localStorage.getItem(key));
    }

    setObject(key: string, obj: any): void {
        const item = localStorage.getItem(key);
        if (item !== null) {
            localStorage.removeItem(key);
        }

        localStorage.setItem(key, JSON.stringify(obj));
    }


    getParty(): PartyResponse {
        return this.getObject(this.partyKey);
    }

    getOrg(): Org {
        return this.getObject(this.orgKey);
    }

    getCurrentUser(): UserInfoResponse {
        return this.getObject(this.userKey);
    }

    getLastUser(): UserInfoResponse {
        return this.getObject(this.lastUserKey);
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }

    getAccessToken(): string {
        const auth = this.getObject(this.authObjKey) as AuthToken;
        if (auth) {
            return auth.accessToken;
        }
        return null;
    }

    getRefreshToken(): string {
        const auth = this.getObject(this.authObjKey) as AuthToken;
        if (auth) {
            return auth.refreshToken;
        }
        return null;
    }

    getItem(key: string): string {
        return localStorage.getItem(key);
    }

    setItem(key: string, item: string): void {
        localStorage.setItem(key, item);
    }

    setOrganization(id: Guid, name: string) {
        this.setObject(this.orgKey, {id: id, name: name});
    }

    getTokenRoles(): string[] {
        const token = this.getAccessToken();
        if (token) {
            const decodedToken: any = jwtDecode(token);
            return decodedToken["role"] || [];
        }
        return [];
    }

    clearPastUserData(){
        this.remove("org");
        this.remove("party");
    }

    getCurrentExpense(): ExpenseResponse {
        return this.getObject('currentExpense');
    }

    setCurrentExpense(expense: ExpenseResponse) {
        this.setObject('currentExpense', expense);
    }

    clearCurrentExpense() {
        this.remove('currentExpense');
    }

    clearCurrentRevenue() {
        this.remove('currentRevenue');
    }

    getCurrentRevenue(): any {
        return this.getObject('currentRevenue');
    }

    setCurrentRevenue(revenue: any) {
        this.setObject('currentRevenue', revenue);
    }
}
