import {Injectable} from '@angular/core';
import {AuthService} from "../../core/auth/auth.service";
import {BehaviorSubject, filter, Observable, shareReplay, tap} from "rxjs";
import {StorageService} from "@portal/shared/services/storage.service";
import {Org} from "@portal/api/responses/organizations/org-details-response";
import {OrganizationsService} from "@portal/api/organizations-service";
import {SearchRequest} from "@portal/api/requests/search-request";

@Injectable({
    providedIn: 'root',
})
// responsible to manage what organization is selected
// and the current list of organizations of the user
export class OrganizationSelectorService {
    private readonly storageKey = 'org';

    private currentSelectedOrg = new BehaviorSubject<Org>(null);
    private currentUserList = new BehaviorSubject<Org[]>(null);

    get org(): Org | null {
        return this.currentSelectedOrg.getValue();
    }

    get org$(): Observable<Org> {
        return this.currentSelectedOrg.asObservable().pipe(filter(item => item != null), shareReplay());
    }

    get orgList$(): Observable<Org[]> {
        return this.currentUserList.asObservable().pipe(filter(item => item != null), shareReplay());
    }

    constructor(private _organizationsService: OrganizationsService,
                private _storageService: StorageService,
                private _authService: AuthService) {
        this.loadOrgFromStorage();
        this.loadAtStart();
        this.listenToUserListChanges();
    }

    private loadAtStart() {
        if (this._authService.isAuthenticated) {
            this.loadUserOrgList();
        }
    }

    private listenToUserListChanges() {
        this.orgList$.subscribe(res => {
            const found = res.find(userList => userList.id === this.currentSelectedOrg.getValue()?.id);
            if (!found && res.length) {
                this.setSelectedOrg(res[0]);
            }
        })
    }

    private loadOrgFromStorage() {
        const org = this._storageService.getObject(this.storageKey);
        if (org) {
            this.setSelectedOrg(org);
        }
    }

    setSelectedOrg(org: Org) {
        if (org.id != this.org?.id ||  this.org === null) {
            this._storageService.setOrganization(org.id, org.name)
            this.currentSelectedOrg.next(org);
        }
    }

    private loadUserOrgList() {
        if (this._authService.isAuthenticated) {
            this._organizationsService.listUserOrganizations({pageSize: 5, page: 0} as SearchRequest)
                .subscribe(res => {
                    this.currentUserList.next(res.data);
                });
        }
    }

    loadOrgList$() {
        return this._organizationsService.listUserOrganizations({
            pageSize: 5,
            page: 0
        } as SearchRequest).pipe(tap(res => {
            this.currentUserList.next(res.data);
        }));
    }

    refreshOrgList() {
        this.loadUserOrgList();
    }

    hasOrgSelected(): boolean {
        return !!this.currentSelectedOrg.getValue();
    }

    resetSelectedOrg() {
        this.currentSelectedOrg.next(null);
    }
}
