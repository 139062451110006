import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {Guid} from "guid-typescript";

interface ModalCalculator {
    open: boolean;
    eventId: Guid;
}

@Injectable({
    providedIn: 'root'
})
export class EventProductsCalculatorService {
    private modal = new BehaviorSubject<Partial<ModalCalculator>>({open: false});

    connect(): Observable<Partial<ModalCalculator>> {
        return this.modal.asObservable();
    }

    modalSet(config: Partial<ModalCalculator>) {
        this.modal.next({...config});
    }
}
