import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '@portal/api/responses/api-response';
import { ApiBaseService } from '@portal/api/api-base-service';
import { PartyPermissionsAddRequest } from '@portal/api/requests/permissions/party-permissions-add-request';
import { Guid } from 'guid-typescript';
import {PermissionsByUserResponse} from "@portal/api/responses/permissions/permissions-by-user-response";
import { OrgPermissionsAddRequest } from '@portal/api/requests/permissions/org-permissions-add-request';

@Injectable({
    providedIn: 'root'
})

export class PartyPermissionsService extends ApiBaseService {
    controller = 'permissions';

    constructor(public http: HttpClient) {
        super(http);
    }

    addPartyPermissions(obj: PartyPermissionsAddRequest): Observable<ApiResponse<boolean>> {
        return this.post<ApiResponse<boolean>, PartyPermissionsAddRequest>(obj, this.controller + '/AddPartyPermissions');
    }

    addOrgPermissions(obj: OrgPermissionsAddRequest): Observable<ApiResponse<boolean>> {
        return this.post<ApiResponse<boolean>, OrgPermissionsAddRequest>(obj, this.controller + '/AddOrgPermissions');
    }

    getPartyPermissionsByUser(partyId: Guid, userId: Guid): Observable<ApiResponse<{read:number, write:number}>> {
        return this.get<ApiResponse<{read:number, write:number}>>(`${this.controller}/GetPartyPermissionByUser?userId=${userId}`);
    }

    getOrgPermissionsByUser(partyId: Guid, userId: Guid): Observable<ApiResponse<{read:number, write:number}>> {
        return this.get<ApiResponse<{read:number, write:number}>>(`${this.controller}/GetOrgPermissionByUser?userId=${userId}`);
    }

    getOwnPermissions(): Observable<ApiResponse<PermissionsByUserResponse>>{
        return this.get<ApiResponse<PermissionsByUserResponse>>(`${this.controller}/GetOwnPermissions`);
    }
}
