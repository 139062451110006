<div class="calc-inner" *ngIf="isCalculatorVisible">
    <div class="close-wrapper">
        <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </div>
    <div class="price">{{ price  | currency: 'EUR'}}</div>

    <div class="grid-container product-container" >
        <button
            *ngFor="let product of products"
            [ngStyle]="{'background-color': (product.backgroundColor) ? product.backgroundColor : '#13b2c3',
                'color': (product.color) ? product.color : '#13b2c3'}"
            [ngClass]="product.touchedTimes> 0 ? 'touched': ''"
            class="product"
            (click)="priceClicked(product.id)"><span>{{ product.name }}</span><br><span
            class="product-price">{{product.price | currency:'EUR'}}</span>
            <span *ngIf="product.touchedTimes" class="counter">{{product.touchedTimes}}</span></button>

    </div>
    <div class="grid grid-cols-3 gap-2">
        <div class="col-span-2">
            <button (click)="resetCalculatingScore()" class="productReset">Apagar</button>
        </div>
        <div class="col-span-1">
            <button (click)="partialDeleteCalculator()"
                    class="productReset"
                    [ngClass]="isPartialDeletingCalculator ? 'focus': ''"
            >CE
            </button>
        </div>
    </div>
</div>
