import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiBaseService} from './api-base-service';
import {ApiResponse, ListResponse} from './responses/api-response';
import {SearchRequest} from './requests/search-request';
import {BehaviorSubject, Observable} from 'rxjs';
import {OrgRequest} from "@portal/api/requests/organizations/org-request";
import {Guid} from "guid-typescript";
import {Org, OrgDetailsResponse} from "@portal/api/responses/organizations/org-details-response";
import {OrgUpdateRequest} from "@portal/api/requests/organizations/org-update-request";
import {UserInfoResponse} from "@portal/api/responses/User/user-info-response";
import {OrgListResponse} from '@portal/api/responses/organizations/org-list-response';
import {OrgAdminUpdateRequest} from "@portal/api/responses/organizations/org-admin-update-request";
import {DataSourceService} from "@portal/api/datasource-service.interface";


@Injectable({
    providedIn: 'root'
})
export class OrganizationsService extends ApiBaseService implements DataSourceService<OrgListResponse,OrgRequest, OrgAdminUpdateRequest>{
    controller= 'orgs';
    data= new BehaviorSubject<OrgListResponse[]>([]);

    constructor(public http: HttpClient) {
        super(http);
    }

    add(obj: OrgRequest) {
        return this.post<ApiResponse<OrgListResponse>, OrgRequest>(obj, this.controller + '/Add');
    }

    deleteUser(userId: Guid) {
        return this.deleteBase<ApiResponse<string>>(`${this.controller}/DeleteUser?userId=${userId.toString()}`);
    }

    orgDetail() {
        return this.get<ApiResponse<OrgDetailsResponse>>(`${this.controller}/Details`);
    }

    listUserOrganizations(obj: SearchRequest): Observable<ApiResponse<Org[]>> {
        return this.post<ApiResponse<Org[]>, SearchRequest>(obj, this.controller + `/ListUserOrganizations`);
    }

    listUsers(obj: SearchRequest): Observable<ApiResponse<ListResponse<UserInfoResponse[]>>> {
        return this.post<ApiResponse<ListResponse<UserInfoResponse[]>>, SearchRequest>(obj, this.controller + `/ListUsers`);
    }

    updateOrg(obj: OrgUpdateRequest): Observable<ApiResponse<OrgListResponse>> {
        return this.put<ApiResponse<OrgListResponse>, OrgUpdateRequest>(obj, this.controller + `/Update`);
    }

    changeOwner(id: string): Observable<ApiResponse<Guid>> {
        return this.post<ApiResponse<Guid>,null>(null, this.controller + `/ChangeOwner?userId=${id}`);
    }

    list(obj: SearchRequest): Observable<ApiResponse<ListResponse<OrgListResponse[]>>> {
        return this.post<ApiResponse<ListResponse<OrgListResponse[]>>, SearchRequest>(obj, this.controller + `/ListAll`);
    }

    delete(id: Guid): Observable<ApiResponse<boolean>> {
        return this.deleteBase<ApiResponse<boolean>>(`${this.controller}/${id}`);
    }

    getFormEdit(orgId: string): Observable<ApiResponse<OrgAdminUpdateRequest>> {
        return this.get<ApiResponse<OrgAdminUpdateRequest>>(this.controller + `/form/edit/${orgId}`);
    }

    update(obj: OrgAdminUpdateRequest): Observable<ApiResponse<OrgListResponse>> {
        return this.put<ApiResponse<OrgListResponse>, OrgAdminUpdateRequest>(obj, this.controller + `/UpdateAdmin`);
    }
}
