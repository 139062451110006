import {Injectable} from '@angular/core';
import {AuthService} from "../../core/auth/auth.service";
import {BehaviorSubject, debounceTime} from "rxjs";
import {StorageService} from "@portal/shared/services/storage.service";
import {Guid} from "guid-typescript";
import {CurrentParty} from "../../core/data/parties/party";

@Injectable({
    providedIn: 'root',
})
export class PartySelectedService {
    private readonly storageKey = 'party';
    private currentParty$ = new BehaviorSubject<CurrentParty>(null);
    get currentParty() {
        return this.currentParty$.asObservable();
    }

    constructor(private _storageService: StorageService,
                private _authService: AuthService) {
        this.loadFromStorage();
    }

    private loadFromStorage() {
        const party = this._storageService.getObject(this.storageKey);
        if (party && this._authService.isAuthenticated) {
            this.setSelectedParty(party.id, party.name);
        }
    }

    setSelectedParty(id: Guid, name: string) {
        const userId = this._storageService.getCurrentUser().id;
        if (userId) {
            this.currentParty$.next({id, name, userId});
            this._storageService.setObject(this.storageKey, this.currentParty$.getValue());
        }
    }

    getPartyId() {
        return this.currentParty$.getValue()?.id;
    }

    get partyName() {
        return this.currentParty$.getValue()?.name;
    }

    setStoragePartyOnSameUser() {
        const currentUserId = this._storageService.getCurrentUser()?.id;
        const storageParty = this.storageParty();
        if (storageParty?.userId === currentUserId) {
            this.setSelectedParty(storageParty.id, storageParty.name);
        }
    }

    storageParty() {
        return this._storageService.getObject(this.storageKey) as CurrentParty;
    }

    resetParty() {
        this.currentParty$.next(null);
    }
}
