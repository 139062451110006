import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Alert} from '@portal/shared/models/alert/alert.interface';
import {AlertService} from "@portal/shared/services/alert-service";

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnDestroy {
    private subscription: Subscription;
    alert: Alert | null = null;

    constructor(private alertService: AlertService) {
        // Listen to the alert service for new messages
        this.subscription = this.alertService.getAlert().subscribe(alert => {
            if (!alert) {
                // Clear alerts when an empty alert is received
                this.alert = null;
                return;
            }
            // Display the alert message
            this.alert = alert;
        });
    }

    ngOnDestroy() {
        // Unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }

    closeAlert() {
        this.alert = null;
    }
}
