import { Injectable } from '@angular/core';
import {Alert} from "@portal/shared/models/alert/alert.interface";
import {BehaviorSubject} from "rxjs";
import {FuseAlertType} from "@portal/fuse/components/alert";

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private alertSubject = new BehaviorSubject<Alert | null>(null);

    getAlert() {
        return this.alertSubject.asObservable();
    }

    private alert(type: FuseAlertType, message: string) {
        const alert = { type, message } as Alert;
        this.alertSubject.next(alert);
        setTimeout(() => {
            this.alertSubject.next(null);
        }, 3000);
    }
    success(message: string) {
        this.alert("success", message);
    }

    error(message: string = '') {
        if (!message) {
            message = 'Ocorreu um erro. Tente de novo.';
        }

        this.alert("error", message);
    }
    info(message: string) {
        this.alert("info", message);
    }

}
