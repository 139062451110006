export const environment = {
    production: false,
    apiUrl: 'https://api-dev.organiza360.pt/api/',
    signalR: 'https://api-dev.organiza360.pt/scoreHub',
    portalUrl: 'https://portal-dev.organiza360.pt/',
    initialPlanPrice: 'price_1Ko9HOAiyxtFUvawXVWtdqik',
    basePlanPrice: 'price_1Ko9GQAiyxtFUvawEWrt4zDu',
    stripeSuccessUrl: 'https://api-dev.organiza360.pt/payments/party-successfully',
    stripeFailureUrl: 'https://api-dev.organiza360.pt/payments/failure',
    stripePublishKey:'pk_test_51Ko8AOAiyxtFUvawe9gD9vyv68ZGHfvRcuIMt1SbaIENHBPflfG73eU0WlYGhbu2XowEZA9G0qrSKA6lg82jeJTO00RDatuXni',
    tinyKey: 'am1kz5y0ot55zjmyuzsrij9twek066sjucc3q7yfctt3lmkd',
    isDemo: true,
    defaultDropdownLimit: 50,
    defaultTableSize: 30,
};
