import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardCardComponent} from './dashboard-card/dashboard-card.component';
import {DashboardSummaryComponent} from './dashboard-summary/dashboard-summary.component';
import {SharedMatTableModule} from '@portal/shared/shared-mat-table.module';
import {RouterModule} from '@angular/router';
import {EditorModule} from '@tinymce/tinymce-angular';
import {AlertComponent} from "./alert/alert.component";


@NgModule({
    declarations: [AlertComponent, DashboardCardComponent, DashboardSummaryComponent],
    exports: [
        DashboardCardComponent,
        DashboardSummaryComponent,
        AlertComponent
    ],
    imports: [
        CommonModule,
        SharedMatTableModule,
        RouterModule,
        EditorModule
    ]
})
export class ComponentsModule { }
