export enum UsersPartyPermissionsReadType{
    IsOwner = 1,
    DonationRead = Math.pow(2, 1), // 2
    GamesRead = Math.pow(2, 2), // 4
    SponsorsRead = Math.pow(2, 3), // 8
    EventsRead = Math.pow(2, 4), // 16
    ExpensesRead = Math.pow(2, 5), // 32
    PartyNotesRead = Math.pow(2, 6), // 64
    RoadmapRead = Math.pow(2, 7), // 128
    RevenuesRead = Math.pow(2, 8), // 256
    RafflesRead = Math.pow(2, 9), // 512
    DashboardRead = Math.pow(2, 10), // 1024
    MembersRead = Math.pow(2, 11), // 2048
    StandsRead = Math.pow(2, 12), // 4096
}
// Máximo é Math.pow(2, 31)
