import {NgModule} from '@angular/core';
import {HasWritePermissionDirective} from '@portal/shared/directives/has-write-permissions.directive';
import {HasNotWritePermissionsDirective} from '@portal/shared/directives/has-not-write-permissions.directive';
import {HasReadPermissionsDirective} from '@portal/shared/directives/has-read-permissions.directive';
import {HasOrgWritePermissionDirective} from "@portal/shared/directives/has-org-write-permission";

@NgModule({
    declarations: [HasWritePermissionDirective, HasNotWritePermissionsDirective, HasReadPermissionsDirective, HasOrgWritePermissionDirective],
    exports: [HasWritePermissionDirective, HasNotWritePermissionsDirective, HasReadPermissionsDirective, HasOrgWritePermissionDirective]
})
export class DirectivesModule {
}
